<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <card>
          <h4 slot="header" class="card-title">{{$t("mediacion")}}</h4>
            <form-wizard @on-complete="onComplete" shape="circle" title="" :finish-button-text="$t('Finalizar')"
                  :back-button-text="$t('back')" :next-button-text="$t('next')" subtitle="" color="#218c39">
              <tab-content :title="$t('DatosMediacion')" :before-change="()=>validateStep('contract-data')" icon="nc-icon nc-notes font-weight-bold">
                <contract-data ref="contract-data" @onCancelMediation="onCancelMediation"  :mediation="mediation"/>
              </tab-content>
              <tab-content :title="$t('DatosContrato')" icon="nc-icon nc-single-02 font-weight-bold">
                <client-data ref="client-data" :fullname="fullname" :mediation="mediation"/>
              </tab-content>        
            </form-wizard>
        </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ContractData from "../../components/WizardViews/ContractData"
  import ClientData from "../../components/WizardViews/ClientData"
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import { apiCallSingle } from '../../mixins'

  export default {
    mixins: [apiCallSingle],
    components: {
      FormWizard,
      TabContent,
      ContractData,
      ClientData
    },
    data () {
      return {
        mediation: {
          request_id : this.id,
          hasHouse : false,
          hasCar  : false,
          hasBank : false,
          hasSocialPart : false,
          hasOthers: false,
        },
        fullname  : null,
        document  : null,
        address   : null,
        city      : null,
        state     : null,
        zipcode   : null,
        endpoint : "request_services"
      }
    },
    created(){
      if(this.mediation_id)
        this.onLoadMediation();
    },
    props : {
      id : String,
      mediation_id : String
    },
    methods: {
      loadData(){
        this.mediation.contact_id = this.data.contact_id;
        this.fullname = this.data.contact.name + " "+this.data.contact.lastname;
        this.document = this.data.contact.document;
        this.address  = this.data.contact.address;
        this.city     = this.data.contact.city;
        this.state    = this.data.contact.state;
        this.zipcode  = this.data.contact.zipcode;
      },
      onComplete () {
        var refToValidate = this.$refs['client-data'];
        let validation = refToValidate.validate();
        if(validation)
          this.onFinishMediation();
      },
      validateStep(name) {
        var refToValidate = this.$refs[name];
        let validation = refToValidate.validate();
        if(validation && !this.mediation.id)
          this.onCreateMediation();
        if(validation && this.mediation.id)
          this.onUpdateMediation();
        return validation;
      },
      onCreateMediation(){
          let self = this;
          this.$axios.post("/mediations", this.mediation)
            .then((response) => {
               let data = response.data;
              data.document = self.document;
              data.address  = self.address;
              data.city     = self.city;
              data.state    = self.state;
              data.zipcode  = self.zipcode;
              self.mediation = data;
            })
      }, 
      onLoadMediation(){
        this.$axios.get("/mediations/"+this.mediation_id)
            .then((response) => {
                this.mediation = response.data;
                this.document = this.mediation.contact.document;
                this.fullname = this.mediation.contact.name + " "+this.mediation.contact.lastname;
                this.address  = this.mediation.contact.address;
                this.city     = this.mediation.contact.city;
                this.state    = this.mediation.contact.state;
                this.zipcode  = this.mediation.contact.zipcode;
            })
      },
      onUpdateMediation(){
        let self = this;
        this.mediation.document = self.document;
        this.mediation.address  = self.address;
        this.mediation.city     = self.city;
        this.mediation.state    = self.state;
        this.mediation.zipcode  = self.zipcode;
        this.$axios.put("/mediations/"+ this.mediation.id, this.mediation)
            .then((response) => {
              let data = response.data;
              data.document = self.document;
              data.address  = self.address;
              data.city     = self.city;
              data.state    = self.state;
              data.zipcode  = self.zipcode;
              self.mediation = data;
            })
      },
      onFinishMediation(){
        let self = this;
        this.mediation.status_id = 2;
        this.$store.dispatch('isLoadingOn');
        this.$axios.put("/mediations/"+ this.mediation.id, this.mediation)
            .then(() => {
              self.$store.dispatch('isLoadingOff');
              self.$router.push("/admin/mediaciones");
            })
             .catch(() => {
              self.$store.dispatch('isLoadingOff');
              self.$dialog.alert({
                  title: self.$t("mediacion"),
                  body: self.$t("server_error")
              })
          })
      },
      onCancelMediation(){
        let self = this;
        this.$store.dispatch('isLoadingOn');
        this.mediation.status_id = 5;
        this.$axios.post("/mediations", this.mediation)
          .then(() => {
            this.$store.dispatch('isLoadingOff');
            self.$router.push("/admin/mediaciones");
          })
          .catch(() => {
              self.$store.dispatch('isLoadingOff');
              self.$dialog.alert({
                  title: self.$t("mediacion"),
                  body: self.$t("server_error")
              })
          })
      }
    }
  }

</script>
<style>
  .wizard-header{
    display: none;
  }
  .wizard-icon-circle.checked {
    background: rgb(33, 140, 57);
  }
</style>
