<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <base-input type="text" :textError="true" :label="$t('fullname')"  v-model="fullname">
                    <!-- <template v-slot:textError v-if="$v.mediation.fullname.$error && !$v.mediation.required">{{ $t("Campo_requerido") }}</template> -->
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input type="text" :label="$t('DNI')" :textError="true"  v-model="mediation.document">
                    <template v-slot:textError v-if="$v.mediation.document.$error && !$v.mediation.document.required">{{ $t("Campo_requerido") }}</template>
                </base-input>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-6" v-if="showPerson">
                <base-input type="text" :label="$t('fullname')"  v-model="mediation.fullname2"></base-input>
            </div>
            <div class="col-md-6" v-if="showPerson">
                <base-input type="text" :label="$t('DNI')"  v-model="mediation.document2"></base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end" >
                <span  v-if="!showPerson" @click="showPerson = true" > 
                    <i class="fas fa-plus mr-3"></i>{{$t("Agregar")}}
                </span>
                <span v-if="showPerson" @click="onRemovePerson">
                    <i class="fas fa-minus mr-3"></i>{{$t("Eliminar")}}
                </span>
            </div>
        </div>
        <div class="row">
              <div class="col-12">
                <base-input type="text" :label="$t('Direccion')" @clickEvent="onSearchAdrress" :addonRightIcon="'fas fa-search'"  v-model="mediation.address">
                  <template v-slot:textError >
                      <span v-if="error_server">
                          {{ error_server }}
                      </span>
                      <span  v-if="$v.mediation.address.$error && !$v.mediation.address.required">
                        {{ $t("Campo_requerido") }}
                      </span>
                  </template>
                  <template v-slot:textInfo >{{ full_address }}</template>    
                </base-input>
              </div>

              <div class="col-md-4">
                  <base-input type="text" :label="$t('Estado')"  v-model="mediation.state"></base-input>
              </div>
              <div class="col-md-4">
                  <base-input type="text" :label="$t('Ciudad')"  v-model="mediation.city"></base-input>
              </div>
              <div class="col-md-4">
                  <base-input type="text" :label="$t('zipcode')"  v-model="mediation.zipcode"></base-input>
              </div>
        </div>
        <div class="row">
            <div class="col-12">
                  <div class="form-group">
                  <label>{{$t('FORMAPAGO')}}</label>
                    <textarea rows="5" class="form-control border-input" v-model="mediation.payment">
                    </textarea>
                    <span class="invalid-feedback" v-if="$v.mediation.payment.$error && !$v.mediation.payment.required">{{ $t("Campo_requerido") }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required} from 'vuelidate/lib/validators';
    export default {
        props : {
            mediation : Object,
            fullname : String
        },
        data() {
            return {
                showPerson : false,
                full_address : null,
                error_server : null,
            }
        },
        validations(){
            return {
               mediation : {
                   document : {
                       required
                   },
                   payment : {
                       required
                   },
                   address : {
                       required
                   },
                   city : {
                       required
                   }
               } 
            }
        },
        methods : {
            onRemovePerson(){
                this.showPerson = false;
                this.mediation.fullname2 = null;
                this.mediation.document2 = null;
            },
            validate() {
                this.$v.mediation.$touch();
                var isValid = !this.$v.$invalid
                return isValid
            },
            onSearchAdrress(){
                this.error_server = null;
                if(this.mediation.address.length >= 3){
                    this.$store.dispatch('isLoadingOn');
                this.$axios("geolocation?search="+this.mediation.address)
                .then((response) => {
                    let data = response.data.data.results;
                    if(data.length > 0)
                    this.onLoadAdrress(data);
                    else
                    this.error_server = this.$t("error_location");
                    this.$store.dispatch('isLoadingOff');
                })
                .catch(() =>{
                    this.$store.dispatch('isLoadingOff');
                    this.error_server = this.$t("error_location");
                })
            }   
      },
      onLoadAdrress(data){
      let address_components = data[0].address_components
      this.full_address = data[0].formatted_address;
       for(let row in address_components){
         let section = address_components[row];
          switch (section.types[0]) {
            case 'locality':
              this.mediation.city = section.long_name;
              break;
            case 'postal_code':
              this.mediation.zipcode = section.long_name;
              break;
            case 'administrative_area_level_2':
              this.mediation.state = section.long_name;
              break;
          }

       }
      }
        },
    }
</script>