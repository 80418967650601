<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <base-select :label="$t('situacionActual')"   :items="situations" v-model="mediation.situation_type_id">
                    <template v-slot:textError v-if="$v.mediation.situation_type_id.$error && !$v.mediation.situation_type_id.required">{{ $t("Campo_requerido") }}</template>
                </base-select>
            </div>
            <div class="col-md-6">
                <money-input   :label="$t('ingresosMensuales')" :currency="'€'" v-model="mediation.income_amount">
                    <template v-slot:textError v-if="$v.mediation.income_amount.$error && !$v.mediation.income_amount.required">{{ $t("Campo_requerido") }}</template>
                </money-input>
            </div>
        </div>
        <div class="row mb-2 importContent">
            <div class="col-12">
                <label class="mb-3">{{$t("Importe_activos")}}</label>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-6">
                        <base-checkbox v-model="mediation.hasHouse"  @input="clearOption('hasHouse','amount_house')">
                            {{$t("Vivienda")}}
                        </base-checkbox>
                    </div>
                    <div class="col-6">
                        <money-input  @change="calcHonoraries" :enabled="mediation.hasHouse" :currency="'€'"  v-model="mediation.amount_house">
                        </money-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <base-checkbox v-model="mediation.hasCar" @input="clearOption('hasCar','amount_car')">
                            {{$t("Vehículos")}}
                        </base-checkbox>
                    </div>
                    <div class="col-6">
                        <money-input  @change="calcHonoraries" :currency="'€'"  :enabled="mediation.hasCar"  v-model="mediation.amount_car"></money-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <base-checkbox v-model="mediation.hasBank"  @input="clearOption('hasBank','amount_bank')" >
                            {{$t("Saldos_bancarios")}}
                        </base-checkbox>
                     </div>
                    <div class=" col-6">
                        <money-input @change="calcHonoraries" :currency="'€'" :enabled="mediation.hasBank"  v-model="mediation.amount_bank"></money-input>
                    </div>
                </div>
            </div>
             <div class="col-md-6">
                <div class="row">
                    <div class="col-6">
                        <base-checkbox v-model="mediation.hasSocialPart"  @input="clearOption('hasSocialPart','amount_social')">
                            {{$t("Part_sociales")}}
                        </base-checkbox>
                    </div>
                    <div class="col-6">
                        <money-input  @change="calcHonoraries" :currency="'€'" :enabled="mediation.hasSocialPart"  v-model="mediation.amount_social"></money-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <base-checkbox v-model="mediation.hasOthers" @input="clearOption('hasOthers','amount_other')">
                            {{$t("Otros")}}
                        </base-checkbox>
                    </div>
                    <div class="col-6">
                        <money-input  @change="calcHonoraries" :enabled="mediation.hasOthers" :currency="'€'"  v-model="mediation.amount_other"></money-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <money-input  @change="calcHonoraries"  :currency="'€'"  :label="$t('importeDeuda')" v-model="mediation.debt_amount">
                    <template v-slot:textError v-if="$v.mediation.debt_amount.$error && !$v.mediation.debt_amount.required">{{ $t("Campo_requerido") }}</template>
                </money-input>
            </div>
            <div class="col-md-6">
                <base-select :label="$t('originDeudas')"  @select="calcHonoraries" :items="debts"  v-model="mediation.debt_type_id">
                    <template v-slot:textError v-if="$v.mediation.debt_type_id.$error && !$v.mediation.debt_type_id.required">{{ $t("Campo_requerido") }}</template>
                </base-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <base-select type="text" :label="$t('CausaInsolvencia')"  :items="insolvencies" v-model="mediation.insolvency_type_id">
                    <template v-slot:textError v-if="$v.mediation.insolvency_type_id.$error && !$v.mediation.insolvency_type_id.required">{{ $t("Campo_requerido") }}</template>
                </base-select>
            </div>
            <div class="col-md-6">
                <base-select :label="$t('prodJudicial')"  :items="procedures" v-model="mediation.procedure_type_id">
                    <template v-slot:textError v-if="$v.mediation.procedure_type_id.$error && !$v.mediation.procedure_type_id.required">{{ $t("Campo_requerido") }}</template>
                </base-select>
            </div>
        </div>
        <div class="row porcent-content">
            <div class="col-12">
                <label for="">{{$t("porcentaje_complejida")}}</label>
            </div>
            <div class="col-md-2 col-6 " v-if="mediation.hasHouse">
                <div class="form-group ">
                    <div class="col-12 px-0">
                        <label class="control-label"> {{$t('hayVivienda')}} </label>
                    </div>
                    <fieldset disabled>
                        <ejs-numerictextbox @change="calcHonoraries" v-model="mediation.porcent_house" :step="0.01" :min="0" :max="0.25" class="form-control" format="p0"/>
                    </fieldset>
                </div>
            </div>
            <div class="col-md-2 col-6 "  v-if="mediation.debt_type_id == 2">
                 <div class="form-group ">
                    <div class="col-12 px-0">
                        <label class="control-label"> {{$t('deudaEmp')}} </label>
                    </div>
                    <fieldset disabled>
                        <ejs-numerictextbox @change="calcHonoraries"  v-model="mediation.porcent_business" :step="0.01" :min="0" :max="0.25" class="form-control" format="p0"/> 
                    </fieldset>
                </div>
            </div>
            <div class="col-md-2 col-6 " v-if="mediation.income_amount >= 2000">
                <div class="form-group ">
                    <div class="col-12 px-0">
                        <label class="control-label"> {{$t('ingresos_superior')}} </label>
                    </div>
                    <fieldset disabled>
                        <ejs-numerictextbox @change="calcHonoraries" v-model="mediation.porcent_income" :step="0.01" :min="0" :max="0.25" class="form-control" format="p0"/> 
                    </fieldset>
                </div>
            </div>
            <div class="col-md-2 col-6 ">
                <div class="form-group ">
                    <div class="col-12 px-0">
                        <label class="control-label"> {{$t('complejidad')}} </label>
                    </div>
                    <fieldset disabled>
                        <ejs-numerictextbox @change="calcHonoraries" v-model="mediation.porcent_complexity" :step="0.01" :min="0" :max="0.25" class="form-control" format="p0"/> 
                    </fieldset>
                </div>
            </div>
            <div class="col-md-2 col-6 "  v-if="getActivesAmount() == 0">
                <div class="form-group ">
                    <div class="col-12 px-0">
                        <label class="control-label"> {{$t('sin_masa')}} </label>
                    </div>
                    <fieldset disabled>
                        <ejs-numerictextbox @change="calcHonoraries" v-model="mediation.porcent_without_actives" :step="0.01" :min="0" :max="0.25" class="form-control" format="p0"/> 
                    </fieldset>
                </div>
            </div>
            <div class="col-12" v-if="showError">
                <div class="invalid-feedback mb-3">
                    {{$t("error_porcent")}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group  input-group-focus">
                    <div class="col-12 px-0"><label class="control-label"> {{$t('HONORARIOS')}} </label></div>
                    <span class="form-control text-right" v-if="!loader"> 
                        {{convertToCurrency(mediation.honoraries)}}
                    </span>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <button class="btn bg-dark btn-dark text-white bottom mt-2"  @click="showModal = true">
                    <span>
                        <i class="fas fa-file-alt mr-2"></i>
                        {{$t("ver_detalle")}}
                    </span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                  <label>{{$t("OBSERVACIONES")}}</label>
                  <textarea rows="5" class="form-control border-input" v-model="mediation.message"></textarea>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-dark bg-dark text-white px-4 py-1 mt-1 position-absolute" @click="onCancelMediation()">
                {{$t("Cancelar")}}
            </button>
        </div>
        <modal v-if="showModal" :showClose="true" @close="showModal=false" :title="$t('honoraries')" :showBtnCancel="false" :size="'lg'" :showBtnAccept="false">
            <table width="100%">
                <tr>
                    <th>{{$t("Activos")}}</th>
                    <th>{{$t("Pasivos")}}</th>
                </tr>
                <tr>
                    <td class="pb-3">{{convertToCurrency(getActivesAmount())}}</td>
                    <td class="pb-3">{{convertToCurrency(mediation.debt_amount)}}</td>
                </tr>
                <tr class="border-bottom">
                    <th class="pb-2">{{$t("porcentaje_complejida")}}</th>
                    <th></th>
                </tr>
                <tr  v-if="mediation.hasHouse">
                    <td class="p-2">{{$t("hayVivienda")}}</td>
                    <td >{{mediation.porcent_house *100}}% </td>
                </tr>
                <tr  v-if="mediation.debt_type_id == 2">
                    <td  class="p-2">{{$t("deudaEmp")}}</td>
                    <td>{{mediation.porcent_business *100}}% </td>
                </tr>
                <tr v-if="!isNaN(mediation.income_amount) && mediation.income_amount >= 2000">
                    <td  class="p-2">{{$t("ingresos_superior")}}</td>
                    <td>{{mediation.porcent_income *100}}% </td>
                </tr>
                <tr>
                    <td  class="p-2">{{$t("complejidad")}}</td>
                    <td>{{mediation.porcent_complexity *100}}% </td>
                </tr>
                <tr v-if="getActivesAmount() == 0">
                    <td class="p-2">{{$t("sin_masa")}}</td>
                    <td >{{mediation.porcent_without_actives *100}}% </td>
                </tr>
                <tr class="border-top">
                    <th class="pt-3">{{$t("TOTAL")}}</th>
                    <th class="pt-3">{{convertToCurrency(mediation.honoraries)}}</th>
                </tr>
            </table>
        </modal>
    </div>
</template>
<script>
    import { required} from 'vuelidate/lib/validators';
    // import { required, maxLength,minLength, integer, url, email } from 'vuelidate/lib/validators';
    import Modal from "@/components/Modal.vue";

    export default {
     data () {
      return {
        debts       : [],
        procedures  : [],
        insolvencies: [],
        situations  : [],
        showError   : false,
        showDetail  : false,
        showModal   : false,
        loader      : false,
        validations : {
            "hasHouse"    : "amount_house",
            "hasCar"      : "amount_car",
            "hasBank"     : "amount_bank",
            "hasSocialPart" : "amount_social",
            "hasOthers"   : "amount_other"
        }
      }
    },
    components: {
        Modal,
    },
    validations(){
        return {
            mediation : {
                situation_type_id :{
                    required
                },
                income_amount : {
                    required
                },
                debt_amount : {
                    required
                },
                debt_type_id : {
                    required
                },
                insolvency_type_id : {
                    required
                },
                procedure_type_id : {
                    required
                }
            }
        }
    },
    created(){
        this.onLoadData();
        this.mediation.porcent_house            = 0.25;
        this.mediation.porcent_business         = 0.25;
        this.mediation.porcent_income           = 0.25;
        this.mediation.porcent_complexity       = 0.25;
        this.mediation.porcent_without_actives  = 0.25;
    },
    methods : {
        onLoadData(){
            this.onLoadDebts();
            this.onLoadSituations();
            this.onLoadInsolvencies();
            this.onLoadJudicialProcedures();
        },
        validate() {
            this.$v.mediation.$touch();
            var isValid = !this.$v.$invalid
            return isValid
        },

        onLoadDebts(){
            this.$axios("/conf/debts_types")
                .then((response) =>{
                    this.debts = response.data;
                })
        },
        onLoadSituations(){
            this.$axios("/conf/situations_types")
                .then((response) =>{
                    this.situations = response.data;
                })
        },
        onLoadInsolvencies(){
            this.$axios("/conf/insolvencies_types")
                .then((response) =>{
                    this.insolvencies = response.data;
                })
        },
        onLoadJudicialProcedures(){
            this.$axios("/conf/judicial_procedures_types")
                .then((response) =>{
                    this.procedures = response.data;
                })
        },
        clearOption(checkValue , modelValue){
            if(!this.mediation[checkValue]){
                this.mediation[modelValue] = 0;
                this.calcHonoraries();
            }
        },
        calcHonoraries(){
            this.loader = true;
            let actives = this.getActivesAmount();
            let debt_amount = this.mediation.debt_amount ? parseFloat(this.mediation.debt_amount) : 0;
            let calc = ( actives * 0.01 ) + (debt_amount * 0.005);
            let totalPrice = this.getFinalPrice(calc);
            if (totalPrice < 1500 || isNaN(totalPrice))
                this.mediation.honoraries = 1500;
            else {
                this.showDetail = true;
                this.mediation.honoraries = totalPrice;
            }
            this.loader = false;
        },
        convertToCurrency(amount){  
          return amount ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount) : 0;
        },
        getActivesAmount(){
            let actives = 0;
            for(let field in this.validations){
                if(this.mediation[field]){
                    let value = this.mediation[this.validations[field]] ? this.mediation[this.validations[field]] : 0
                    actives = actives +  parseFloat(value);
                }
            }
            return isNaN(actives) ? 0 : actives;
        },
        getFinalPrice(price){
            let totalPrice = price;                 
            totalPrice += this.mediation.income_amount >= 2000 ? price * this.mediation.porcent_income : 0;
            totalPrice += this.mediation.hasHouse ? price * this.mediation.porcent_house : 0;
            totalPrice += this.mediation.debt_type_id == 2  ? price * this.mediation.porcent_business : 0;
            totalPrice += this.getActivesAmount() == 0      ? price * this.mediation.porcent_without_actives : 0;
            totalPrice += price * this.mediation.porcent_complexity;
            return totalPrice;
        },
        onCancelMediation(){
            this.$emit("onCancelMediation");
        }
    },
    props : {
        mediation : Object
        },
    }
</script>
<style>
    .porcent-content label{
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        width: 100%;
    }
    @media (min-width: 768px){
        .porcent-content .col-md-2 {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
    .e-input[disabled]{
        -webkit-text-fill-color : #495057 !important;
    }
</style>